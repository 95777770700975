import React from 'react';
import clsx from 'clsx';

import { BaseComponentProps } from '@utils/types/baseComponents';

import styles from './styles.module.scss';

type Props = {
  label: string;
  onClick: () => void;
  checked: boolean;
} & BaseComponentProps;

const Radio = (props: Props) => {
  const { label, containerStyle, onClick, checked, theme = 'dark' } = props;

  return (
    <div
      className={clsx([styles.radioChoice, containerStyle])}
      onClick={onClick}
    >
      <div className={clsx([styles.radio, styles[theme]])}>
        <div
          className={clsx([
            styles.radio__check,
            styles[theme],
            checked && styles.isChecked,
          ])}
        />
      </div>
      <label>{label}</label>
    </div>
  );
};

export default Radio;
