import _isInteger from 'lodash/isInteger';
import _isNumber from 'lodash/isNumber';

export function toDollars(amount: number, decimals: number | null = null) {
  const numberOfDecimals = _isNumber(decimals)
    ? decimals
    : _isInteger(amount)
    ? 0
    : 2;
  return amount.toLocaleString('us-EN', {
    currency: 'usd',
    style: 'currency',
    minimumFractionDigits: numberOfDecimals,
    maximumFractionDigits: numberOfDecimals,
  });
}
