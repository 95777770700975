import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Link from 'next/link';
import _isEmpty from 'lodash/isEmpty';

import { BaseComponentProps } from '@utils/types/baseComponents';
import Header from '@components/layout/header';
import Footer from '@components/layout/footer';
import AnnouncementBanner from '@components/layout/announcementBanner';
import { GlobalSettingsType } from '@api/global/getGlobalSettings';
import { useGetGlobalSettings } from '@utils/hooks/api/useGetGlobalSettings';
import { useGetMarkets } from '@utils/hooks/api/useGetMarkets';
import { useGetActiveLandingsSlugs } from '@utils/hooks/api/useGetActiveLandingsSlugs';
import { useGetStayLengthCollections } from '@utils/hooks/api/useGetStayLengthCollections';

import styles from '@styles/_styles.module.scss';

export type LayoutProps = {
  footerProps?: { noParallaxImage?: boolean } & BaseComponentProps;
  headerProps?: BaseComponentProps;
  announcementBannerProps?: BaseComponentProps;
};

const Layout = (props: PropsWithChildren<LayoutProps>) => {
  const { headerProps, footerProps, announcementBannerProps, children } = props;
  const [announcementBanner, setAnnouncementBanner] =
    useState<GlobalSettingsType['announcementBanner']>();
  const [markets, setMarkets] = useState<any>();
  const [stayLengthCollections, setStayLengthCollections] = useState<any>();

  const globalSettings = useGetGlobalSettings();
  const { refetch: refetchMarkets } = useGetMarkets(false);
  const { refetch: refetchStayLengthCollections } =
    useGetStayLengthCollections(false);
  const activeLandingsSlugs = useGetActiveLandingsSlugs();

  useEffect(() => {
    if (typeof localStorage === 'undefined') {
      return undefined;
    }

    const cachedMarkets = sessionStorage.getItem('markets')
      ? // @ts-ignore
        JSON.parse(sessionStorage.getItem('markets'))
      : undefined;
    const cachedStayLengthCollections = sessionStorage.getItem('markets')
      ? // @ts-ignore
        JSON.parse(sessionStorage.getItem('stayLengthCollections'))
      : undefined;

    if (cachedMarkets) {
      setMarkets(cachedMarkets);
    } else {
      refetchMarkets().then(({ data }) => {
        setMarkets(data);
        sessionStorage.setItem('markets', JSON.stringify(data));
      });
    }

    if (cachedStayLengthCollections) {
      setStayLengthCollections(cachedStayLengthCollections);
    } else {
      refetchStayLengthCollections().then(({ data }) => {
        setStayLengthCollections(data);
        sessionStorage.setItem('stayLengthCollections', JSON.stringify(data));
      });
    }
  }, []);

  useEffect(() => {
    if (!_isEmpty(globalSettings)) {
      const shouldShowAnnouncement =
        !!globalSettings.announcementBanner &&
        !!globalSettings.announcementBanner.isShown &&
        !!globalSettings.announcementBanner.text &&
        !!globalSettings.announcementBanner.text[0] &&
        !!globalSettings.announcementBanner.text[0].children;

      /** Change to true, never shown now */
      if (shouldShowAnnouncement === undefined) {
        setAnnouncementBanner(globalSettings.announcementBanner);
      }
    }
  }, [globalSettings.announcementBanner]);

  const browseRentalsLinks = useCallback(
    (type: 'search' | 'collection') => (
      <>
        {/* @ts-ignore */}
        {markets?.map(({ name, slug }) => (
          <Link
            href={
              type === 'search'
                ? `/search?market=${slug.current}`
                : `/collections/${slug.current}`
            }
            key={slug.current}
          >
            <a>{name}</a>
          </Link>
        ))}
        <Link href={`/search`}>
          <a>
            <p>View all on map</p>
          </a>
        </Link>
      </>
    ),
    [markets]
  );

  const stayLengthCollectionLinks = useMemo(
    () => (
      <>
        {/* @ts-ignore */}
        {stayLengthCollections?.map(({ name, slug }) => (
          <Link href={`/collections/${slug.current}`} key={slug.current}>
            <a>{name}</a>
          </Link>
        ))}
      </>
    ),
    [stayLengthCollections]
  );

  const propertyManagementLinks = useMemo(
    () => (
      <>
        <Link href={`/propertymanagement/new-york-city`}>
          <a>New York City</a>
        </Link>

        {activeLandingsSlugs?.map(({ title, slug }) => (
          <Link href={`/propertymanagement/${slug}`} key={slug}>
            <a>{title}</a>
          </Link>
        ))}
      </>
    ),
    [activeLandingsSlugs]
  );

  return (
    <>
      {!!announcementBanner && (
        <AnnouncementBanner
          {...announcementBannerProps}
          text={announcementBanner.text}
        />
      )}

      <Header
        {...headerProps}
        browseRentalsLinks={browseRentalsLinks('search')}
        propertyManagementLinks={propertyManagementLinks}
      />

      <main className={styles.layout__main}>{children}</main>

      <Footer
        {...footerProps}
        browseRentalsLinks={browseRentalsLinks('collection')}
        stayLengthCollectionLinks={stayLengthCollectionLinks}
        propertyManagementLinks={propertyManagementLinks}
      />
    </>
  );
};

export default Layout;
