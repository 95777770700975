import { useEffect, useState } from 'react';
import {
  getGlobalSettings,
  GlobalSettingsType,
} from '@api/global/getGlobalSettings';

export function useGetGlobalSettings() {
  const [globalSettings, setGlobalSettings] =
    useState<GlobalSettingsType | null>();

  useEffect(() => {
    getGlobalSettings().then(setGlobalSettings);
  }, []);

  return globalSettings || {};
}
