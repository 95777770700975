import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import Link from 'next/link';

import { colorsByTheme } from '@utils/constants/colors';
import { Contacts } from '@utils/constants/global';
import { Cross, Cellphone, Envelope, ChevronDown } from '@assets/icons';
import { useToggleBodyScroll } from '@utils/hooks/toggleBodyScroll';
import { profileDropdownLinks } from '@components/layout/constants';

import styles from './styles.module.scss';

type Props = {
  onClose: () => void;
  logOut: () => void;
};

const AdminBurgerMenu = (props: Props) => {
  const { onClose, logOut } = props;
  const { disableScroll, enableScroll } = useToggleBodyScroll();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    disableScroll();
    return () => {
      enableScroll();
    };
  }, []);

  return (
    <section className={styles.adminBurgerMenu}>
      <div className={styles.adminBurgerMenu__header}>
        <Cross
          onClick={onClose}
          stroke={colorsByTheme['dark'].textPrimaryColor}
        />
      </div>

      <div className={styles.adminBurgerMenu__body}>
        <section className={styles.adminBurgerMenu__links} onClick={onClose}>
          <div
            onClick={(e) => {
              e.stopPropagation();
              setIsDropdownOpen((prevState) => !prevState);
            }}
            className={clsx([
              styles.adminBurgerMenu__item,
              styles.withBorderTop,
              isDropdownOpen && styles.highlighted,
            ])}
          >
            Owner&apos;s Portal
            <ChevronDown stroke={colorsByTheme['dark'].textPrimaryColor} />
          </div>

          {isDropdownOpen && (
            <section className={styles.adminBurgerMenu__rentals}>
              <Link href={`/owners-portal`}>
                <a>Listings</a>
              </Link>
              <Link href={`/owners-portal/reservations`}>
                <a>Reservations</a>
              </Link>
              <Link href={`/owners-portal/messages`}>
                <a>Messages</a>
              </Link>
            </section>
          )}

          {profileDropdownLinks.map((link) => (
            <Link href={link.href} key={link.href}>
              <a className={styles.buttonContainer}>{link.name}</a>
            </Link>
          ))}

          <span onClick={logOut}>Sign Out</span>
        </section>
      </div>

      <div className={styles.adminBurgerMenu__footer}>
        <a
          href={`tel:${Contacts.CALL_US}`}
          className={clsx([styles.header__phoneNumber, 'fade-animation'])}
        >
          <Cellphone fill={colorsByTheme['dark'].textPrimaryColor} />
        </a>
        <a
          className={styles.header__contactIcon}
          href={`mailto:${Contacts.CONTACT_EMAIL}`}
        >
          <Envelope fill={colorsByTheme['dark'].textPrimaryColor} />
        </a>
      </div>
    </section>
  );
};

export default AdminBurgerMenu;
