enum CheckoutLinks {
  CREATE_ACCOUNT = '/checkout/create-account',
  // We do not use this route currently, but it is functional
  RENTAL_CONTRACT = '/checkout/rental-contract',
  RESERVATION_CONFIRMATION = '/checkout/reservation-confirmation',
  SIGN_IN = '/checkout/sign-in',
  SIGN_UP = '/checkout/sign-up',
  VERIFY_YOUR_EMAIL = '/checkout/verify-your-email',
  WELCOME = '/checkout/welcome',
  PAYMENT_METHOD = '/checkout/payment-method',
  COMPLETE_PURCHASE = '/checkout/complete-purchase',
  TERMS_OF_SERVICE = '/terms-of-service',
  RENTAL_AGREEMENT = '/rental-agreement',
}

export default CheckoutLinks;
