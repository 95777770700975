import { SessionTokensType } from '@utils/types/sessionTokens';
import { UserType } from '@utils/types/user';
import { apiInstance } from 'api/client';

type GetUserDetailsResponse = {
  user: UserType;
  sessionTokens: SessionTokensType;
};

export async function getUserDetails(): Promise<GetUserDetailsResponse> {
  const response = await apiInstance.post(`/v1/auth`, { grant_type: 'token' });

  return {
    sessionTokens: {
      access_token: response.data.access_token,
      refresh_token: response.data.refresh_token,
    },
    user: response.data.user,
  };
}
