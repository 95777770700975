import { apiInstance } from '../client';

export type SubscribeTags =
  | 'individual'
  | 'owner'
  | 'company'
  | 'renter'
  | 'buyer';

type SubscribeInfo = {
  email: string;
  tags: SubscribeTags[];
  isRenter?: boolean;
  isBuyer?: boolean;
  isOwner?: boolean;
};

export async function subscribe(signUpInfo: SubscribeInfo): Promise<void> {
  await apiInstance.post(`/v2/newsletter/signup`, {
    ...signUpInfo,
  });
}
