import React from 'react';
import clsx from 'clsx';
import { Logo as LogoIcon } from '@assets/icons';

import { BaseComponentProps } from '@utils/types/baseComponents';
import { colors } from '@utils/constants/colors';

import styles from './styles.module.scss';

type Props = {} & BaseComponentProps;

const Logo = (props: Props) => {
  const { theme, containerStyle } = props;

  return (
    <div className={clsx([styles.logoContainer, containerStyle])}>
      <LogoIcon fill={colors[theme === 'dark' ? 'roveWhite' : 'roveBlack']} />
    </div>
  );
};

export default Logo;
