import { PaymentMethod as PaymentMethodType } from '@utils/types/paymentMethods';
import { DatesRange } from '@utils/types/calendar';
import { differenceInDays } from 'date-fns';

export enum PaymentMethodEnum {
  CARD = 'card',
  BANK_ACCOUNT = 'bank_account',
  SEPA_DEBIT = 'sepa_debit',
}

export const getPaymentMethodTitle = (
  type: PaymentMethodType['type'],
  methodInfo?: PaymentMethodType
) => {
  if (methodInfo) {
    return `${methodInfo.data.name} ${
      type === PaymentMethodEnum.CARD ? '**** **** **** ' : '*****'
    }${methodInfo.data.last4}`;
  }

  switch (type) {
    case PaymentMethodEnum.BANK_ACCOUNT:
      return 'Bank Transfer';
    case PaymentMethodEnum.CARD:
      return 'Credit Card';
    case PaymentMethodEnum.SEPA_DEBIT:
      return 'International Bank Transfer (SEPA)';
    default:
      return '';
  }
};

export const getPaymentMethodShortTitle = (type: PaymentMethodType['type']) => {
  switch (type) {
    case PaymentMethodEnum.BANK_ACCOUNT:
      return 'ACH';
    case PaymentMethodEnum.CARD:
      return 'Credit Card';
    case PaymentMethodEnum.SEPA_DEBIT:
      return 'SEPA';
    default:
      return '';
  }
};

/**
 * If date range is >= 45 days then we can split the payment
 */
export const defineSplitPayment = (dates: DatesRange) => {
  return (
    !!dates.to &&
    !!dates.from &&
    differenceInDays(new Date(dates.to), new Date(dates.from)) >= 45
  );
};
