import { SessionTokensType } from '@utils/types/sessionTokens';
import { UserType } from '@utils/types/user';
import { apiInstance } from 'api/client';
import { SignUpInfo } from '@api/auth/signUp';

type SignUpConfirmationResponse = {
  user: UserType;
  sessionTokens: SessionTokensType;
  redirectPage: string;
};

export async function signUpConfirmation(
  token: string,
  signUpInfo?: SignUpInfo
): Promise<SignUpConfirmationResponse> {
  const response = await apiInstance.post(
    `/v1/signup/confirmation/${token}`,
    signUpInfo
      ? {
          payload: {
            first_name: signUpInfo.firstName,
            last_name: signUpInfo.lastName,
            email: signUpInfo.email,
            password: signUpInfo.password,
            phoneNumber: signUpInfo.phoneNumber,
            tos_acceptance: {
              data: new Date(),
            },
          },
        }
      : undefined
  );
  return {
    sessionTokens: {
      access_token: response.data.access_token,
      refresh_token: response.data.refresh_token,
    },
    user: response.data.user,
    redirectPage: response.data.metadata.page,
  };
}
