import { getCollectionsByType } from '@api/collections/getCollectionsByType';
import { useQuery } from 'react-query';

export function useGetStayLengthCollections(enabled = true) {
  return useQuery(
    'getStayLengthCollections',
    () => getCollectionsByType(['stay_length']),
    {
      enabled,
      refetchOnWindowFocus: false,
    }
  );
}
