export const profileDropdownLinks = [
  {
    name: 'Reservations & Stays',
    href: '/reservations-and-stays',
  },
  {
    name: 'Reviews',
    href: '/reviews',
  },
  {
    name: 'Messages',
    href: '/messages',
  },
  {
    name: 'Account Settings',
    href: '/account-settings',
  },
];

export const profileDropdownLinksAdmin = [
  {
    name: "Owner's Portal",
    href: '/owners-portal',
  },
];
