import { SessionTokensType } from '@utils/types/sessionTokens';
import { UserType } from '@utils/types/user';
import { apiInstance } from 'api/client';

export type LoginResponse = {
  user: UserType;
  sessionTokens: SessionTokensType;
};

export async function login(
  email: string,
  password: string
): Promise<LoginResponse> {
  const response = await apiInstance.post(`/v1/auth`, {
    grant_type: 'login',
    email,
    password,
  });

  return {
    sessionTokens: {
      access_token: response.data.access_token,
      refresh_token: response.data.refresh_token,
    },
    user: response.data.user,
  };
}
