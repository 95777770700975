import React from 'react';
import clsx from 'clsx';

import { BaseComponentProps } from '@utils/types/baseComponents';
import WysiwygText from '@components/_shared/wysiwygText';

import styles from './styles.module.scss';

type Props = {
  text: any;
} & BaseComponentProps;

const AnnouncementBanner = (props: Props) => {
  const { text, containerStyle } = props;

  return (
    <div className={clsx([styles.announcementBanner, containerStyle])}>
      <WysiwygText content={text} />
    </div>
  );
};

export default AnnouncementBanner;
