import React, { PropsWithChildren, useEffect } from 'react';

import AdminHeader from '@components/layout/adminHeader';
import { useSession } from '@utils/context/session';
import { useRouter } from 'next/router';
import { getSessionTokens } from '@utils/localStorage';

import styles from '@styles/_styles.module.scss';

const AdminLayout = (props: PropsWithChildren<{}>) => {
  const { children } = props;
  const { user } = useSession();
  const router = useRouter();

  useEffect(() => {
    if (!user) {
      const sessionTokens = getSessionTokens();
      if (!sessionTokens) router.push('/home');
    } else {
      if (user && !user.is_owner) {
        router.push('/home');
      }
    }
  }, [user]);

  if (!user) {
    return null;
  }

  return (
    <>
      <AdminHeader />
      <main className={styles.layout__main}>{children}</main>
    </>
  );
};

export default AdminLayout;
