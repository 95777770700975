import { groqRequest } from '@api/client';

export type GlobalSettingsType = {
  announcementBanner?: {
    isShown?: boolean;
    // rich editor content
    text?: any;
  };
  // rich editor content
  blogSlogan?: any;
};

export const GET_GLOBAL_SETTINGS = () => `
  *[_type == "globalSettings" && _id == 'globalSettings']{...}
`;

export async function getGlobalSettings(): Promise<GlobalSettingsType | null> {
  const cmsContent = await groqRequest(GET_GLOBAL_SETTINGS());
  return cmsContent.data.result[0];
}
