/// <reference types="@types/segment-analytics" />

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
  }
}

export enum SegmentEventPagesEnum {
  HOME_PAGE = 'Home Page',
  SEARCH_PAGE = 'Search Page',
  LISTING_PAGE = 'Listing Page',
  ITINERARY_PAGE = 'Itinerary Page',
  CHECKOUT_PAGE = 'Checkout Page',
  MARKETPLACE_PAGE = 'Marketplace Page',
}

export enum SegmentEventNamesEnum {
  LOGIN_BUTTON_CLICK = 'Login button click',
  SIGNUP_BUTTON_CLICK = 'Signup button click',
  CONTINUE_WITH_FACEBOOK_CLICK = 'Continue with Facebook Click',
  CONTINUE_WITH_GOOGLE_CLICK = 'Continue with Google Click',
  SIGNUP_CLICK = 'Signup Click',
  LOGIN_CLICK = 'Login button click',
  SEARCH_CLICK = 'Search clicked',
  SELECT_MARKET_CLICKED = 'Select a Market clicked',
  CHECK_IN_DATES_APPLIED = 'Check in dates applied',
  CHECK_OUT_DATES_APPLIED = 'Check out dates applied',
  FEATURED_LISTING_TAB_CLICKED = 'Featured listing tab clicked',
  FEATURED_LISTING_CLICKED = 'Featured listing clicked',
  LISTING_CLICKED = 'Listing Clicked',
  FILTER_APPLIED = 'Filter applied',
  MARKET_CHANGED = 'Market changed',
  NEIGHBORHOODS_CHANGED = 'Neighborhoods changed',
  FUZZY_DATES_OFFSET_CHANGED = 'Fuzzy dates offset changed',
  CHECK_IN_DATE_APPLIED = 'Check in date applied',
  CHECK_OUT_DATE_APPLIED = 'Check out date applied',
  BOOK_NOW_CLICKED = 'Book now clicked',
  REQUEST_TO_BOOK_CLICKED = 'Request to Book clicked',
  AGREE_AND_CONTINUE_CLICKED = 'Agree and Continue clicked',
  EDIT_GUEST_NAME_CLICKED = 'Edit Guest Name clicked',
  EDIT_PHONE_NUMBER_CLICKED = 'Edit Phone Number clicked',
  ADD_PAYMENT_METHOD_CLICKED = 'Add Payment Method clicked',
  SAVE_PAYMENT_METHOD_CLICKED = 'Save Payment Method clicked',
  COMPLETE_PURCHASE_CLICKED = 'Complete Purchase clicked',
  SEND_BOOKING_REQUEST_CLICKED = 'Send Booking Request clicked',
  INQUIRE_HERE_CLICKED = 'Inquire here clicked',
  MAKE_AN_OFFER_CLICKED = 'Make an Offer clicked',
  AGREE_AND_CONTINUE = 'Agree and Continue',
}

export function useAnalytics() {
  const trackEvent = (name: SegmentEventNamesEnum, params?: {}) => {
    if (window && window.analytics) {
      window.analytics.track(name, params);
    }
  };

  const identifyUser = (
    id: string,
    traits: { name?: string; email: string }
  ) => {
    if (window && window.analytics) {
      window.analytics.identify(id, traits);
    }
  };

  return {
    trackEvent,
    identifyUser,
  };
}
