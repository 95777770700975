import { useEffect, useState } from 'react';
import { getActiveLandingSlugs } from '@api/landings/getActiveLandingSlugs';

export function useGetActiveLandingsSlugs() {
  const [activeLandingSlugs, setActiveLandingSlugs] = useState<
    | {
        slug: string;
        title: string;
      }[]
    | null
  >();

  useEffect(() => {
    getActiveLandingSlugs().then((response) => {
      setActiveLandingSlugs(
        response.filter(({ slug }) => slug !== 'new-york-city')
      );
    });
  }, []);

  return activeLandingSlugs || [];
}
