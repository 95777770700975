import React, { PropsWithChildren, useEffect } from 'react';
import clsx from 'clsx';

import { BaseComponentProps } from '@utils/types/baseComponents';

import styles from './styles.module.scss';

type ParallaxImageSectionType = {
  uniqueElementID: string;
  imageUrl: string;
  backgroundPositionX?: string;
  overflowStyle?: string;
} & BaseComponentProps;

const ParallaxImageSection = (
  props: PropsWithChildren<ParallaxImageSectionType>
) => {
  const {
    uniqueElementID,
    imageUrl,
    backgroundPositionX = 'center',
    children,
    overflowStyle = '',
    containerStyle = '',
    theme = 'dark',
  } = props;

  const addParallaxToContainer = () => {
    const scrollPosition = window.pageYOffset;
    const parallaxElement = document.getElementById(uniqueElementID);
    if (parallaxElement) {
      const limit = parallaxElement.offsetTop + parallaxElement.offsetHeight;
      if (
        scrollPosition > parallaxElement.offsetTop &&
        scrollPosition <= limit
      ) {
        parallaxElement.style.backgroundPositionY = `calc(100% + ${
          (scrollPosition - parallaxElement.offsetTop) / 6
        }px)`;
      } else {
        parallaxElement.style.backgroundPositionY = '100%';
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', addParallaxToContainer);
    return () => {
      window.removeEventListener('scroll', addParallaxToContainer);
    };
  }, []);

  return (
    <div
      id={uniqueElementID}
      data-parallax-initial-offset="80%"
      className={clsx([
        styles.parallaxImageSection,
        'with-parallax',
        containerStyle,
      ])}
      style={{ backgroundImage: `url('${imageUrl}')`, backgroundPositionX }}
    >
      <div
        className={clsx([
          styles.parallaxImageSection__overflow,
          styles[theme],
          overflowStyle,
        ])}
      />
      {children}
    </div>
  );
};

export default ParallaxImageSection;
