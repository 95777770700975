import { groqRequest } from '@api/client';

export const GET_ACTIVE_LANDING_SLUGS = () => `
  *[_type == "landing" && !(_id in path("drafts.**")) && isActive == true]
  {
    "slug": slug.current,
    title,
  }
`;

export async function getActiveLandingSlugs(): Promise<
  { slug: string; title: string }[]
> {
  const response = await groqRequest(GET_ACTIVE_LANDING_SLUGS());
  return response.data.result;
}
