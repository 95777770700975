import React, { useRef, useState } from 'react';
import clsx from 'clsx';

import { colorsByTheme } from '@utils/constants/colors';
import { BaseComponentProps } from '@utils/types/baseComponents';
import { useClickOutside } from '@utils/hooks/useClickOutside';
import { ChevronDown } from '@assets/icons';

import styles from './styles.module.scss';

type Props = {
  children: string | JSX.Element;
  dropdownContent: JSX.Element;
  withArrow?: boolean;
  dropdownStyle?: string;
} & BaseComponentProps;

const ButtonWithDropdown = (props: Props) => {
  const {
    children,
    dropdownContent,
    theme = 'light',
    containerStyle,
    dropdownStyle = '',
    withArrow = true,
  } = props;
  const [isDropdownShown, setIsDropdownShown] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useClickOutside(containerRef, () => setIsDropdownShown(false));

  const onHandleDropdown = () => setIsDropdownShown((prevState) => !prevState);

  return (
    <div
      className={clsx([
        styles.buttonWithDropdown,
        containerStyle,
        isDropdownShown && styles.isOpen,
      ])}
      onClick={onHandleDropdown}
      ref={containerRef}
    >
      {children}
      {withArrow && (
        <ChevronDown stroke={colorsByTheme[theme].textPrimaryColor} />
      )}
      <div
        className={clsx([
          styles.buttonWithDropdown__dropdown,
          'fade-animation',
          dropdownStyle,
          isDropdownShown && styles.isOpen,
        ])}
      >
        {dropdownContent}
      </div>
    </div>
  );
};

export default ButtonWithDropdown;
