import React from 'react';
import clsx from 'clsx';
import PortableText from 'react-portable-text';

import { BaseComponentProps } from '@utils/types/baseComponents';

import styles from './styles.module.scss';

type Props = {
  content: any;
} & BaseComponentProps;

const WysiwygText = (props: Props) => {
  const { content, containerStyle } = props;

  return (
    <div className={clsx([styles.wysiwygText, containerStyle])}>
      <PortableText
        content={content}
        serializers={{
          link: ({ children, href }: any) => (
            <a href={href} target="_blank" rel="noopener noreferrer">
              {children}
            </a>
          ),
        }}
      />
    </div>
  );
};

export default WysiwygText;
