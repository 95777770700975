import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import Link from 'next/link';

import ButtonWithDropdown from '@components/_shared/buttonWithDropdown';
import { useSession } from '@utils/context/session';
import Logo from '@components/layout/logo';
import AdminBurgerMenu from '@components/layout/adminBurgerMenu';
import {
  profileDropdownLinks,
  profileDropdownLinksAdmin,
} from '@components/layout/constants';
import NavLink from '@components/_shared/navLink';

import styles from './styles.module.scss';

const AdminHeader = () => {
  const { user, logOut } = useSession();
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const addScrollListenerToWindow = () => {
    if (window)
      window.addEventListener('scroll', () =>
        setIsScrolled(window.scrollY > 0)
      );
  };

  useEffect(() => {
    addScrollListenerToWindow();
  }, []);

  const handleBurgerMenu = () => setIsBurgerMenuOpen((prevState) => !prevState);

  const profileDropdown = useMemo(
    () => (
      <>
        {[...profileDropdownLinksAdmin, ...profileDropdownLinks].map((link) => (
          <Link href={link.href} key={link.href}>
            <a className={styles.buttonContainer}>{link.name}</a>
          </Link>
        ))}

        <span onClick={logOut}>Sign Out</span>
      </>
    ),
    []
  );

  return (
    <header
      className={clsx([
        styles.adminHeader,
        isScrolled && !isBurgerMenuOpen && styles.isScrolled,
      ])}
    >
      <Link href="/">
        <a className={styles.adminHeader__block}>
          <Logo />
        </a>
      </Link>

      <section
        className={clsx([
          styles.adminHeader__capsule,
          isScrolled && styles.isScrolled,
        ])}
      >
        <NavLink
          href="/owners-portal"
          className={styles.adminHeader__link}
          activeClassName={styles.adminHeader__activeLink}
        >
          Listings
        </NavLink>

        <NavLink
          href="/owners-portal/reservations"
          className={styles.adminHeader__link}
          activeClassName={styles.adminHeader__activeLink}
        >
          Reservations
        </NavLink>

        <NavLink
          href="/owners-portal/messages"
          className={styles.adminHeader__link}
          activeClassName={styles.adminHeader__activeLink}
        >
          Messages
        </NavLink>
      </section>

      <div className={clsx([styles.adminHeader__block, styles.userBlock])}>
        <ButtonWithDropdown
          dropdownContent={profileDropdown}
          containerStyle={styles.adminHeader__hiUser}
          dropdownStyle={clsx([
            styles.adminHeader__dropdown,
            styles.userDropdown,
          ])}
          withArrow={false}
        >
          <span>Hi, {user?.first_name}</span>
        </ButtonWithDropdown>
      </div>

      <div
        className={styles.adminHeader__burgerIcon}
        role="button"
        onClick={handleBurgerMenu}
      >
        <div />
        <div />
      </div>

      {isBurgerMenuOpen && (
        <AdminBurgerMenu onClose={handleBurgerMenu} logOut={logOut} />
      )}
    </header>
  );
};

export default AdminHeader;
