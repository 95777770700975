'use client';

import Link, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';

import React, { PropsWithChildren, useEffect, useState } from 'react';

type NavLinkProps = LinkProps & {
  activeClassName: string;
  title?: string;
  className?: string;
  Icon?: React.FC;
};

const NavLink = ({
  children,
  activeClassName,
  className,
  ...props
}: PropsWithChildren<NavLinkProps>) => {
  const { pathname } = useRouter();
  const [computedClassName, setComputedClassName] = useState(className);

  useEffect(() => {
    // Dynamic route will be matched via props.as
    // Static route will be matched via props.href
    const linkPathname = new URL(
      (props.as || props.href) as string,
      location.href
    ).pathname;

    // Using URL().pathname to get rid of query and hash
    const activePathname = new URL(pathname, location.href).pathname;

    const newClassName =
      linkPathname === activePathname
        ? `${className} ${activeClassName}`.trim()
        : className;

    if (newClassName !== computedClassName) {
      setComputedClassName(newClassName);
    }
  }, [
    pathname,
    props.as,
    props.href,
    activeClassName,
    className,
    computedClassName,
  ]);

  return (
    <Link {...props}>
      <a className={computedClassName}>{children}</a>
    </Link>
  );
};

export default NavLink;
