import React from 'react';

import { SessionProvider } from '@utils/context/session';
import { ListingProvider } from '@utils/context/listing';
import { PageWithProvidersAndLayout } from '@utils/types/nextApp';
import Layout, { LayoutProps } from '@components/layout/layout';
import AdminLayout from '@components/layout/adminLayout';

export function withAdminLayoutAndSession<ComponentProps = any>(
  page: PageWithProvidersAndLayout<ComponentProps>
) {
  page.getLayout = function (page: PageWithProvidersAndLayout<ComponentProps>) {
    return <AdminLayout>{page}</AdminLayout>;
  };
  return withSession(page);
}

export function withLayoutAndSession<ComponentProps = any>(
  page: PageWithProvidersAndLayout<ComponentProps>,
  layoutProps: LayoutProps
) {
  page.getLayout = function (page: PageWithProvidersAndLayout<ComponentProps>) {
    return <Layout {...layoutProps}>{page}</Layout>;
  };
  return withSession(page);
}

export function withSession<ComponentProps = any>(
  component: PageWithProvidersAndLayout<ComponentProps>
) {
  component.sessionProvider = SessionProvider;
  return component;
}

export function withListing<ComponentProps = any>(
  component: PageWithProvidersAndLayout<ComponentProps>
) {
  component.listingProvider = ListingProvider;
  return component;
}
