export enum Global {
  MORTGAGE_LENGTH = 30,
  CLOSING_COST = 5,
}

export enum Contacts {
  CONTACT_EMAIL = 'concierge@rovetravel.com',
  FACEBOOK_URL = 'https://www.facebook.com/people/Rove-Travel/100087552227642/',
  INSTAGRAM_URL = 'https://www.instagram.com/rove_travel/',
  WHATSAPP_URL = 'https://wa.me/16468086638',
  OWNERS_SITE_URL = 'https://owners.rovetravel.com',
  CAREERS_SITE_URL = 'https://rovetravel.notion.site/Rove-Careers-6b20b1e95ed44000bc66eb4b56aa4931',
  TERMS_AND_PRIVACY_URL = 'https://assets.website-files.com/60a2ac87e0551a10d703b77d/60c7e0715b90bc47889066e8_Rove%20Ventures%20Inc.-Terms%20of%20Service-03_01_2021.pdf',
  CALL_US = '877-510-ROVE',
}
